import Tile from "components/general/Tile";
import Loader from "components/general/Loader";
import "./main-tiles-section.scss";

export default function MainTilesSection({ posts }) {
		
	return (
		<div className="main-tiles-section">
			<div className="container"> 

				{ !posts && <Loader style={{ height: "450px" }}/>}

				{ posts && !!posts.length &&
					<div className="main-tiles-section-items"> 
						{ posts.map(( item, i ) => (
							<Tile
								key={ i }
								id={ item.id }
								image={ item.image }
								title={ item.title }
							/>
						))}
					</div>
				}
			</div>
		</div>
	)
}