import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "svg/icons";
import Paths from "router/Paths";
import "./tile.scss";

export default function Tile({ id, title, text, image }) {

	const classes = [ "tile", image ? "with-img" : "" ].join(" ");

	return (
		<Link to={ Paths.Post( id )} className={ classes }>
			<div className="tile-title"> { title } </div>

			{ text && <p className="tile-text"> { text } </p> }

			{ image && 
				<div 
					className="tile-img"
					style={{ backgroundImage: `linear-gradient(0deg, rgba(51, 121, 93, 0.4), rgba(51, 121, 93, 0.4)), url(${ image })`}}	
				/>
			}

			<ArrowForwardIcon/>
		</Link>
	)
}