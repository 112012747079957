import Carousel from "components/general/Carousel";
import Tile from "components/general/Tile";

export default function SeeAlsoSection({ items }) {
		
	return (
		<Carousel
			heading="Zobacz też inne"
			items={ items }
			ItemComponent={ Tile }
		/>
	)
}