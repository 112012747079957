import { useMemo, forwardRef } from "react";
import HTMLReactParser from "html-react-parser";

import { getGroupMaxValue, getGroupTotalValue } from "./helpers";
import withAnimateTrigger from "hoc/withAnimateTrigger";
import BarsGroup from "./inner/BarsGroup";

import "./bar-chart.scss";

const BarChart = forwardRef( function BarChart( props, ref ) {
	
	const { animate } = props;
	const { title, subtitle, data, show_percent } = props;
	

	const max_value = useMemo(() => {
		
		if ( !data ) return;

		const max_data_value = getGroupMaxValue( data );
		const max_data_value_str = "" + max_data_value.toFixed();
		const max_value = ( +( max_data_value_str ).slice( 0, 1 ) + 1 ) + "0".repeat( max_data_value_str.length - 1 );

		return +max_value;
	}, [ data ])


	const total_value = useMemo(() => {
		if ( !data ) return;
		return getGroupTotalValue( data );
	}, [ data ]);


	const scale = useMemo(() => {

		if ( !max_value ) return;

		const scale_step = max_value % 6 === 0 ? max_value / 6 : max_value / 5;
		const result = [ max_value ];

		for ( let i = max_value; i > scale_step; ) {
			i -= scale_step;
			result.push( i );
		}

		return result;

	}, [ max_value ]);
	

	const data_groups = !!Array.isArray( data[0].data );
	const group_common = { max_value, total_value, show_percent, animate };

	return (
		<div className="bar-chart">
			
			{ title && <h3> { HTMLReactParser( title )} </h3> }
			{ subtitle && <h4> { HTMLReactParser( subtitle )} </h4> }	

			<div className="bar-chart-wrap"> 

				<div className="bar-chart-scale">
					{ scale.map( value => (
						<div key={ value }> 
							<span> { value } </span> 
						</div>
					))}
				</div>

				<div className="bar-chart-main has-scrollbar"> 
					<div ref={ ref } className="bar-chart-bars">
						{ data && !!data?.length && 
							data_groups
								? data.map( group => (
									<BarsGroup 
										key={ group.label }
										data={ group.data }
										color={ group.color }
										{...group_common }	
									/>
								)) : <BarsGroup data={ data } {...group_common }/>
						}
					</div>

					<div className="bar-chart-labels">
						{ !!data?.length && 
							data.map( group => {
									
									const { label } = group;
									const is_group = !!group.data?.length;

									return (
										<div 
											key={ label } 
											className={ is_group ? "group" : "" }
											style={{ width: 120 * ( group.data?.length || 1 ) + "px" }}
										> 
											{ is_group && group.data.map( item => ( <div key={ item.label }> { item.label } </div> )) }
											
											<div style={{ margin: is_group ? "auto auto 0" :"" }}> 
												{ HTMLReactParser( label || "" )} 
											</div>
										</div>
									)
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
})

export default withAnimateTrigger( BarChart ); 