import { useState, useRef } from "react";
import useDidChange from "hooks/useDidChange";
import useCategoryPosts from "hooks/data/useCategoryPosts";
import { useDataContext } from "context/DataContext";

import MainSection from "sections/MainSection";
import TilesSection from "sections/TilesSection";
import { scrollSmoothTo } from "extra/functions";

export default function ArticlesPage() {

	const posts_box_ref = useRef();
	const { news_category_id } = useDataContext();
	const [ page, setPage ] = useState( 1 );

	const articles = useCategoryPosts( news_category_id, {
		page: page - 1,
		limit: 10,
		basic: 1
	});


	useDidChange(() => {
		scrollSmoothTo( posts_box_ref.current );
	}, [ articles?.contents ])

	return (
		<>
			<MainSection
				title="Działania"
				text="Pełna lista artykułów"
			/>
			
			<TilesSection
				ref={ posts_box_ref }
				posts={ articles?.contents }
				pagination={{
					page: page,
					pages_amount: articles?.pages?.pageCount,
					onChange: p => setPage( p )
				}}
			/>
		</>
	)
}