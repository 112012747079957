import { useRef } from "react";
import useWindowScroll from "hooks/useWindowScroll";
import { detectScrollToElementBottom } from "extra/functions";


export default function withAnimateTrigger( Component ) {
	return function( props ) {

		const element_ref = useRef();
		const animate = useWindowScroll( detectStartAnimation );

		function detectStartAnimation() {
			const element = element_ref?.current;
			return element ? detectScrollToElementBottom( element ) : false;
		}

		return (
			<Component 
				{...props } 
				ref={ element_ref }
				animate={ animate } 
			/>
		)
	}
}

