import Paths from "router/Paths";

import { useDataContext } from "context/DataContext";
import MainSlider from "sections/MainSlider";
import MainTilesSection from "sections/MainTilesSection";
import TilesSection from "sections/TilesSection";


export default function MainPage() {

	const { slides, promoted_news, home_news } = useDataContext();
	
	return (
		<>
			<MainSlider slides={ slides }/> 

			<MainTilesSection posts={ promoted_news }/>

			<TilesSection
				heading="Więcej artykułów"
				text="Zagadnienia dotyczące zmian klimatycznych - przegląd najważniejszych informacji i działań, które każdy z nas może podjąć, aby pomóc w walce ze zmianami klimatycznymi."
				posts={ home_news }
				more={{
					label: "Wyświetl pełną listę artykułów",
					to: Paths.Articles
				}}
			/>
		</>
	)
}