
export default function MainSliderList({ slides, active_slide, transition_time, onClick }) {
		
	return (
		<div className="main-slider-list">
			{ slides.map(({ title }, i ) => {

				const active = active_slide === i;

				return (
					<div 
						key={ i } 
						className={ active ? "active" : "" }
						onClick={() => onClick( i )}
					>
						<span> { title } </span>
						
						<div> 
							<div style={{ transition: active ? `width ${ transition_time / 1000 }s linear` : "" }}/>
						</div>
					</div>
				)
			})}
		</div>
	)
}