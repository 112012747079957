import { PlusIcon, MinusIcon, AIcon } from "svg/icons";
import { changeFontSize } from "extra/theme";


const font_switcher = [
	{ action: "less", title: "mniejszy rozmiar czcionki", content: <MinusIcon/> },
	{ action: "normal", title: "normalny rozmiar czcionki", content: <AIcon/> },
	{ action: "more", title: "większy rozmiar czcionki", content: <PlusIcon/> }
];

export default function FontSizeSwitcher() {
	return (
		<div className="font-size-switcher">
			{ font_switcher.map( item => (
				<a 
					href="#" 
					key={ item.action } 
					title={ item.title }
					className={`font-size-${ item.action }`}
					onClick={ e => { 
						e.preventDefault(); 
						changeFontSize( item.action )
					}}
				> 
					{ item.content } 
					<span className="d-none"> { item.title } </span> 
				</a>

			)) }
		</div>
	)
}