import { useEffect, useState } from 'react';
import { isObject } from "extra/functions";
import useCancelToken from './useCancelToken';

let cached = {}

export default function useFetchData({
	APIMethod,
	params = [],
	condition,
	cache_name
}) {

	const [ data, setData ] = useState( getDefaultData());
	const [ getCancelToken, cancelQuery ] = useCancelToken();

	function getDefaultData() {
		const c = cached[ cache_name ];
		return ( !c || c === "loading" ) ? null : c;
	}


	const getCachedData = () => {
		cached[ cache_name ] === "loading"
			? setTimeout(() => getCachedData(), 200 )
			: setData( cached[ cache_name ])
	}


	const getData = params => {

		setData( null );
		cancelQuery();

		APIMethod( ...params, getCancelToken())
		.then( data => {

			if ( cache_name ) cached[ cache_name ] = data;
			setData( data );
		})
		.catch(() => {
			if ( cache_name ) cached[ cache_name ] = null 
		});
	}


	useEffect(() => {

		if ( condition === false ) return;

		if ( cache_name && cached[ cache_name ]) getCachedData();
		else {
			if ( cache_name ) cached[ cache_name ] = "loading";
			getData( params )
		}
	}, params.reduce(
		( all_params, current ) => {
			isObject( current ) 
				? Object.values( current ).forEach( p => all_params.push( p ))
				: all_params.push( current )
			return all_params;
		}, []
	));


	return data;
}