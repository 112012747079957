import API from "api/API";
import useFetchData from "hooks/useFetchData";

export default function( category_id, params = {}) {

	return useFetchData({
		APIMethod: API.getCategoryPosts,
		params: [ category_id, params ],
		condition: !!category_id
	})
}