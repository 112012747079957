import { useEffect, useRef, useState } from "react";


export default function AnimatedValue({ value, animate }) {
	
	const timer = useRef();
	let [ animated_value, setAV ] = useState( 0 );

	const setAnimatedValue = new_value => {
		animated_value = new_value;
		setAV( new_value );
	}


	useEffect(() => {

		if ( animate && animated_value === 0 ) {

			const step = Math.round( value / 100 );

			const increment = () => {
				
				if ( animated_value < value ) {

					const new_value = animated_value + step >= value ? value : animated_value + step;
					setAnimatedValue( new_value );

				} else clearInterval( timer.current )
			}
			
			timer.current = setInterval(() => increment(), 10 );

		} else if ( !animate ) {
			clearInterval( timer.current );
			setAnimatedValue( 0 );
		}

	}, [ animate ])

	return (
		<span> { animated_value } </span>
	)
}

