import Bar from "../Bar";

export default function BarsGroup({ data, color, animate, max_value, total_value, show_percent }) {
		
	return (
		<>
			{ data && !!data?.length &&  
				data.map( item => {

					const value = item.value;
					const value_height = ( value / max_value  * 100 ) + "%";
					const percent = show_percent 
						? ( value / total_value * 100 ).toFixed(1)
						: ""

					return (
						<Bar
							key={ value }
							value={ value }
							percent={ percent }
							color={ color }
							value_height={ value_height }
							animate={ animate }
						/>
					)
				})
			}
		</>
	)
}

