import Paths from "router/Paths";
import Carousel from "components/general/Carousel/Carousel";
import Tile from "components/general/Tile/Tile";

export default function ArticlesCarouselSection( props ) {
	
	const items = props.items && !!props.items?.length 
		? props.items.map( item => ({
			id: item.id,
			title: item.title,
			text: item.short
		}))
		: props.items;

	return (
		<Carousel
			className="green article"
			heading="Zobacz też"
			more={{
				to: Paths.Articles,
				label: "Wyświetl pełną listę artykułów"
			}}
			items={ items }
			ItemComponent={ Tile }
		/>
	)
}