import { AngleLeftIcon, AngleRightIcon } from "svg/icons";
import "./arrows.scss";

export default function Arrows({ extra_classes = "", onClick }) {
		
	return (
		<div className={`arrows ${ extra_classes }`}>
			{[ "prev", "next" ].map( action => (
				<button key={ action } type="button" onClick={() => onClick( action )}>
					{ action === "prev" ? <AngleLeftIcon/> : <AngleRightIcon/> }
				</button>
			))}
		</div>
	)
}