import TileWithGraphics from "components/general/TileWithGraphics"
import Parser from "extra/parser"

export default function ContentSection({ id, title, header_text, svg, content, charts }) {
		
	return (
		<section id={ id }>
			<div className="content-container">

				{ header_text && 
					<TileWithGraphics
						text={ title }
						background={ svg }
					/>
				}

				{ Parser( content || "", charts )}
			</div>
		</section> 
	)
}

