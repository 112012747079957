export const getGroupMaxValue = group => group.reduce(( max, current ) => {
	const value = current.value || getGroupMaxValue( current.data );
	return max > value ? max : value;
}, 0 );

export const getGroupTotalValue = group => group.reduce(( total, current ) => {
	const value = current.value || getGroupTotalValue( current.data );
	return total + value;
}, 0 );

