import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import API from "api/API";
import { useDataContext } from "context/DataContext";

export default function() {

	const route_params = useParams();
	const POST_ID = route_params.id;
	const { news_category_id, getMenuPostById } = useDataContext();

	const [ post, setPost ] = useState( getMenuPostById( POST_ID ) || null );
	const [ linked_posts, setLinkedPosts ] = useState( undefined );
	const [ same_category_posts, setSameCategoryPosts ] = useState( undefined );

	const getLinkedPosts = async() => {		
		setLinkedPosts( null );

		const l = await API.getLinkedPosts( POST_ID );
		setLinkedPosts( l );
	}


	const getSameCategoryPosts = async() => {
		setSameCategoryPosts( null );

		const p = await API.getCategoryPosts( news_category_id, { basic: 1 });
		setSameCategoryPosts( p?.contents || []);
	}


	const getPost = async() => {

		const menu_post = getMenuPostById( POST_ID );
		
		if ( menu_post ) {
			setSameCategoryPosts( undefined );
			getLinkedPosts();
			setPost( menu_post );
			return;
		};
		
		const post = await API.getPostById( POST_ID );
		setLinkedPosts( undefined );
		getSameCategoryPosts();
		setPost( post );
	}


	useEffect(() => { getPost()}, [ POST_ID ])

	return { post, linked_posts, same_category_posts }
}