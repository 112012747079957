import { useRef, useState, useEffect } from "react";

import Button from "components/buttons/Button/Button";
import Navigation from "components/general/Navigation";
import MainSliderList from "./components/MainSliderList";

import "./main-slider.scss";

export default function MainSlider({ slides, ...props }) {
	
	const transition_time = props.transition_time || 5500;
	const slides_auto_change = useRef();

	let [ active_slide, setActiveSlide ] = useState( -1 );
	const [ auto_change, setAutoChange ] = useState( true );

	const startAutoChange = () => {
		slides_auto_change.current = setInterval(() => {
			changeSlide( "next" );	
		}, transition_time );
	}


	const changeSlide = i => {

		let slide_i;

		if ( typeof i === "number" )
			slide_i = i ; 
		else {

			const slides_l = slides?.length;

			switch ( i ) {
				case "prev": slide_i = active_slide - 1 < 0 
					? slides_l - 1
					: active_slide - 1 
					break;

				case "next": slide_i = active_slide + 1 === slides_l
					? 0
					: active_slide + 1
					break;
			}
		}

		active_slide = slide_i;
		setActiveSlide( slide_i );
	}


	useEffect(() => {
		active_slide = 0;
		setActiveSlide(0);
	}, [ slides ]);


	useEffect(() => {
		auto_change ? startAutoChange() : clearInterval( slides_auto_change );
		return () => clearInterval( slides_auto_change.current );
	}, [ auto_change ])


	if ( !slides || !slides?.length ) return null;

	return (
		<div className="main-slider">
			<div>
				{ slides.map(({ title, text, to, img }, i ) => (
					<div 
						key={ i }
						className={`main-slider-slide ${ i === active_slide ? "active" : "" }`}
						style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.07) 54.35%), linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50.77%), url(${ img })`}}
					>
						<div className="container"> 
							<h1> { title } </h1>
							{ text && <p> { text } </p> }

							{ to && <Button to={ to }> Czytaj więcej </Button> }
						</div>
					</div>
				))}

				<Navigation/>

				{ slides?.length > 1 && 
					<MainSliderList 
						active_slide={ active_slide }
						slides={ slides }
						transition_time={ auto_change ? transition_time : 0 }
						onClick={ i => {
							setAutoChange( false );
							changeSlide( i )
						}}	
					/>
				}
			</div>
		</div>
	)
}