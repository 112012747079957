import Axios from "axios";

const default_params = window.config.DEFAULT_PARAMS || {};

const API = Axios.create({ 
	baseURL: window.config.API_URL
});


API.interceptors.request.use( config => {

	const c = config || {};

	if ( c?.method === "get" ) {
		for ( const k in default_params ) {
			c.params 
				? c.params[ k ] = default_params[ k ]
				: c.params = {[k]: default_params[ k ]}
		}
	}

	return c;
});


const getMainCategoryId = async() => {
	const resp = await API.get( `/contents/top_category_by_domain/klimat` );
	return resp.data.id;
}


const getSliderId = async() => {
	const resp = await API.get( `/contents/domain_info/klimat` );
	return resp.data.slider;
}


const getMainSlider = async() => {
	const slider_id = await getSliderId();
	const resp = await API.get( `/contents/slides/${ slider_id }` );
	
	return resp.data.slides.map( i => ({
		title: i.title,
		text: i.description || "",
		img: i.name,
		to: i.url
	}));
}


const getCarbonFootprintData = async() => {
	const resp = await API.get( `contents/posts/slugs/carbon-footprint` );
	return resp.data?.posts?.[0]
}


const getSubcategories = async( category_id, cancelToken ) => {
	
	const resp = await API.get(`/contents/subcategories/${ category_id }`, { cancelToken });
	const subcategories = resp?.data?.subcategories?.categories; 

	return subcategories;
}


const getAllCategories = async() => {
	const main_category_id = await getMainCategoryId();
	return await getSubcategories( main_category_id );
}


const getCategoryPosts = async( category_id, params = {}, cancelToken ) => {

	const resp = await API.get(`/contents/posts/category/${ category_id }`, {
		params: {
			page: params.page || 0,
			limit: params.limit || 10,
			promoted: params.promoted,
			unpromoted: params.unpromoted
		}, cancelToken
	});

	return resp?.data?.posts;
}


const getPostById = async( id, cancelToken ) => {
	const resp = await API.get(`/contents/posts/${ id }`, { cancelToken });
	return resp?.data?.posts?.[0];
}


const getLinkedPosts = async( id, cancelToken ) => {
	const resp = await API.get( `/contents/linked/${ id }`, { cancelToken });
	return resp?.data;
}


export default {
	getSliderId,
	getMainSlider,
	getCarbonFootprintData,
	getAllCategories,
	getCategoryPosts,
	getPostById,
	getLinkedPosts
};

