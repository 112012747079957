import { useDataContext } from "context/DataContext";

import MainSection from "sections/MainSection/MainSection";
import ContentSection from "sections/ContentSection";

import main_section_bg from "img/carbon-footprint.jpg";
import "./carbon-footprint-page.scss";

export default function CarbonFootprintPage() {
	
	const { carbon_footprint_data } = useDataContext();
	const extended_data = carbon_footprint_data?.extended_data; 
	const sections = extended_data?.pages;
	const charts = extended_data?.charts;

	return (
		<>
			<MainSection
				title="Ślad węglowy"
				text={ carbon_footprint_data.short }
				img={ main_section_bg }
			/>

			<div className="carbon-footprint-page">
				{ sections && !!sections.length && 
					sections.map(( section, i ) => (
						<ContentSection
							key={ i + 1 }
							id={`section-${ i + 1 }`}
							{...section }
							charts={ charts }
						/>
					))
				}
			</div>
		</>
	)
}