import HTMLReactParser from "html-react-parser";

import BarChart from "components/charts/BarChart";
import PieChart from "components/charts/PieChart";
import List from "components/general/List";

export default function Parser( html, charts = []) {

	return html ? HTMLReactParser( html, {
		replace: node => {
			
			const is_tag = node.type === "tag";
			if  ( !is_tag ) return;

			switch ( node.name ) {
				// case "ul":	
				// return <List type={ type } items={ items }/>
	
				case "div":

					const node_id = node.attribs?.id;
					const chart = charts?.find( c => c?.ID === node_id )

					if ( !chart ) return 
					
					return chart.type === "pie" 
						? <PieChart {...chart }/> 
						: <BarChart {...chart }/>
			}
		} 
	}) : null
}