import "./tile-with-graphics.scss";

export default function TileWithGraphics({ text, background }) {
		
	return (
		<div 
			className="tile-with-graphics with-bg"
			style={{ backgroundImage: `url(${ background })`}}
		>
			<h2> { text } </h2>
		</div>
	)
}

