import { forwardRef } from "react";
import Tile from "components/general/Tile";
import Button from "components/buttons/Button";
import Pagination from "components/general/Pagination";
import Loader from "components/general/Loader";

import "./tiles-section.scss";

export default forwardRef( function TilesSection({ posts, heading, text, more = {}, pagination = {} }, ref ) {
	
	return (
		<div ref={ ref } className="tiles-section">
			<div className="container"> 
				
				{ heading && <h3> { heading } </h3>}
				{ text && <p> { text } </p> }

				{ !posts && <Loader style={{ height: "450px" }}/>}

				<div className="tiles-section-items">
					{ posts && !!posts.length &&
						posts.map(( item, i ) => (
							<Tile
								key={ i }
								id={ item.id }
								title={ item.title }
								text={ item.short }
							/>	
						))
					}
				</div>

				{ more.label && more.to &&
					<div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
						<Button to={ more.to }> { more.label } </Button>
					</div>
				}

				<Pagination {...pagination }/>
			</div>
		</div>
	)
})