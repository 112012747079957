import Paths from "./Paths";
import { Route, Routes as Switch } from "react-router-dom"

import MainPage from "pages/MainPage";
import ArticlesPage from "pages/ArticlesPage"
import CarbonFootprintPage from "pages/CarbonFootprintPage";
import ArticlePage from "pages/ArticlePage";

const pages = [
	{
		Component: MainPage,
		path: Paths.News
	},
	{
		Component: ArticlesPage,
		path: Paths.Articles
	},
	{
		Component: CarbonFootprintPage,
		path: Paths.CarbonFootprint
	},
	{
		Component: ArticlePage,
		path: Paths.Post()
	}
]


export default function Routes() {
	return (
		<Switch>
			{ pages.map(({ path, Component }) => {

				return (
					<Route
						exact
						key={ path }
						path={ path } 
						Component={ Component }
					/>
				)
			})}
		</Switch>
	)
}