import { useEffect } from "react";
import Routes from "router/Routes";
import useLocationChange from "hooks/useLocationChange";
import { scrollSmoothTo } from "extra/functions";
import { checkContrastAtStart } from "extra/theme";

import DataContextProvider from "context/DataContext";
import Header from "components/main/Header";
import Footer from "components/main/Footer";

import "styles/main.scss";

export default function App() {

	useLocationChange( scrollSmoothTo );

	useEffect(() => { checkContrastAtStart()}, []);

	useEffect(() => {

		
		const setVh = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${ vh }px`);
		}

		setVh();
		
		window.addEventListener('resize', setVh );
		return () => window.removeEventListener( "resize", setVh )
	})

	return (
		<>
			<Header/>

			<main>
				<DataContextProvider>
					<Routes/>
				</DataContextProvider>
			</main>

			<Footer/>
		</>
	);
}