import { useRef, forwardRef } from "react";

const SwipeableBox = forwardRef(( props, ref ) => {
	
	const touch_ref = useRef();
	const { children, onSwipeLeft, onSwipeRight, ...rest } = props;

	const onTouchStart = e => {
		touch_ref.current = e.changedTouches[0]
	}


	const onTouchEnd = e => {
		
		const touch_start = touch_ref.current;
		const touch_end = e.changedTouches[0];

		if ( !touch_start ) return;
	
		if ( 
			Math.abs( touch_start.clientX - touch_end.clientX ) < 24 || 
			Math.abs( touch_start.clientY - touch_end.clientY ) > 20 
		) return;
			
		touch_start.clientX > touch_end.clientX ? onSwipeLeft(): onSwipeRight();
		touch_ref.current = null;	
	}

	
	return (
		<div
			ref={ ref } 
			{...rest }
			onTouchStart={ onTouchStart }
			onTouchEnd={ onTouchEnd }
		>
			{ children }
		</div>
	)
})

export default SwipeableBox;