import Navigation from "components/general/Navigation";
import "./main-section.scss";


export default function MainSection({ title, text, img }) {
	
	const classes = [ "main-section", img ? "with-img" : '' ].join(" ");

	return (
		<div className={ classes }>
			<div style={{ backgroundImage: img ? `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.07) 54.35%), linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50.77%), url(${ img })` : null }}> 

				<h1> { title } </h1>
				{ text && <p> { text } </p> }

				<Navigation/>
			</div>
		</div>
	)
}