import { createContext, useContext, useMemo } from "react";

import useSlides from "hooks/data/useSlides";
import useCarbonFootprintData from "hooks/data/useCarbonFootprintData";
import useCategories from "hooks/data/useCategories";
import useMenuPosts from "hooks/data/useMenuPosts";

import Loader from "components/general/Loader";
import useCategoryPosts from "hooks/data/useCategoryPosts";

const DataContext = createContext();

export default function DataContextProvider({ children }) {

	const slides = useSlides();
	const carbon_footprint_data = useCarbonFootprintData();
	const categories = useCategories();
	const menu_posts = useMenuPosts();

	const news_category_id = useMemo(() => categories?.find( c => c.name === "Aktualności" )?.id, [ categories ]);

	const promoted_news = useCategoryPosts( news_category_id, {
		limit: 4,
		page: 0
	})?.contents;

	const home_news = useCategoryPosts( news_category_id, { 
		limit: 4,
		page: 1
	})?.contents;

	const loading = !slides || !carbon_footprint_data || !categories || !menu_posts || !news_category_id;
	
	const getMenuPostById = id => {
		
		const all_posts = Object.values( menu_posts ); 

		for ( const posts of all_posts ) {
			const p = posts.find( p => p.id === id )
			if ( p ) return p;
		}

		return null;
	}


	return (
		<DataContext.Provider value={{
			slides,
			carbon_footprint_data,
			categories,
			news_category_id,
			menu_posts,
			promoted_news,
			home_news,
			getMenuPostById,
		}}>
			{ loading ? <Loader style={{ height: "100%" }}/> : children }
		</DataContext.Provider>
	)
}

export const useDataContext = () => useContext( DataContext );