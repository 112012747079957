import AnimatedValue from "components/general/AnimatedValue";
import "./bar.scss";

export default function Bar({ value, percent, animate, color, value_height }) {
	
	return (
		<div className="bar">

			<div 
				className="bar-numbers"
				style={{ bottom: animate ? value_height : 0 }}	
			> 
				{ percent && <span style={{ opacity: +!!animate }}> { percent }% </span> }
				<AnimatedValue value={ value } animate={ animate }/>
			</div>

			<div 
				className="bar-value-view" 
				style={{ height: animate ? value_height : 0, background: color }}
			/> 
		</div>
	)
}

