import { useState, useEffect } from "react";
import API from "api/API";
import useCategories from "hooks/data/useCategories";

export default function () {

	const categories = useCategories();	
	const [ categories_posts, setCategoriesPosts ] = useState( null );

	const getCategoriesPosts = async() => {
		
		const result = {};
		const with_posts = [ "Wiedza", "Projekty", "Działania", "Dotacje" ];
		
		await Promise.all(
			categories
			.filter( c => with_posts.includes( c.name ))
			.map( async( c ) => {
				const resp = await API.getCategoryPosts( c.id );
				result[ c.name ] = resp.contents;
			})
		)

		setCategoriesPosts( result );
	}

	useEffect(() => { categories && getCategoriesPosts()}, [ categories ]);

	return categories_posts;
}