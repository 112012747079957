import Parser from "extra/parser"
import usePostData from "hooks/data/usePostData";
import MainSection from "sections/MainSection";
import SeeAlsoSection from "sections/SeeAlsoSection";
import Loader from "components/general/Loader";
import ArticlesCarouselSection from "sections/ArticlesCarouselSection";


export default function ArticlePage() {
	
	const { post, linked_posts, same_category_posts } = usePostData();

	return post ? (
		<>
			<MainSection 
				title={ post.title }
				img={ post.image }
			/>

			<div
				className="content-container"
				style={{ paddingTop: "50px", paddingBottom: "50px" }}
			>
				{ Parser( post.content_app )}

				{ post.author && 
					<p style={{ marginTop: "40px" }}> Autor: { post.author } </p>
				}
			</div>

			{( linked_posts || linked_posts === null ) &&
				<SeeAlsoSection items={ linked_posts }/>
			}

			{( same_category_posts || same_category_posts === null ) &&
				<ArticlesCarouselSection items={ same_category_posts }/>
			}
		</>
	) : <Loader style={{ height: "100%" }}/>
}